import * as React from 'react';
import { StaticImage } from 'gatsby-plugin-image';
import Layout from '../components/layout';
import Container from '../components/container';
import Header from '../components/header';
import Advantages from '../components/advantages';
import CheckIcon from '../images/advantages/check.svg';

function List({ items, className }) {
  return (
    // <ul className="grid grid-cols-2 gap-8 mt-12 text-xl">
    <ul className="mt-12 space-y-6">
      {items.map(item => (
        <li
          className="flex items-center space-x-2 text-lg leading-none text-gray-600 truncate"
          key={item}
        >
          <CheckIcon className="shrink-0" />
          <span className="truncate">{item}</span>
        </li>
      ))}
    </ul>
  );
}

// Item.propTypes = {
//   title: PropTypes.string.isRequired,
//   children: PropTypes.string.isRequired,
//   className: PropTypes.string,
//   icon: PropTypes.elementType.isRequired,
// };

// Item.defaultProps = {
//   className: null,
// };

export default function ServicesPage() {
  return (
    <Layout>
      <Container className="mt-12 md:mt-20 lg:mt-32 ">
        <Header
          isMain
          h1="We’re here to do it all"
          text="We are ready to work with you at every stage, from product strategy and design to
          implementation and maintenance."
        />

        <div className="mt-20 space-y-20 lg:space-y-44">
          <div className="xl:items-center lg:grid-cols-2 lg:grid lg:gap-8">
            <StaticImage
              className="hidden shrink-0 lg:block"
              src="../images/photos/drawing.jpg"
              alt="Sketching dashboard design with a pen"
              placeholder="blurred"
              layout="fullWidth"
              quality="90"
            />
            <div className="lg:pl-16">
              <p className="text-sm font-bold tracking-wide text-indigo-600 uppercase md:text-base">
                Analysis & Research
              </p>
              <h3 className="mt-2 text-4xl font-bold text-gray-900 lg:text-6xl" id="strategy">
                Product strategy
              </h3>

              <p className="mt-6 text-xl text-gray-600 ">
                A strategy is essential to make sure that we achieve our goals. We start with
                extensive market research and competitive analysis. Then we deeply analyze audience
                needs and business goals, establishing a clear vision and identifying success
                metrics.
              </p>

              <p className="mt-4 text-xl text-gray-600 ">
                Then we develop user personas and detailed journey maps for each customer segment to
                inform important future decisions and set the key milestones to achieve our vision.
              </p>

              <List
                items={[
                  'Market Research',
                  'Audience Needs Analysis',
                  'Business Goals Analysis',
                  'Product Vision Definition',
                  'User Personas & Journey Maps',
                  'Identifying Success Metrics',
                ]}
              />
            </div>
          </div>

          <div className="xl:items-center lg:grid-cols-2 lg:grid lg:gap-8">
            <div className="lg:pr-16">
              <p className="text-sm font-bold tracking-wide text-indigo-600 uppercase md:text-base">
                Research-driven
              </p>
              <h3 className="mt-2 text-4xl font-bold text-gray-900 lg:text-6xl" id="design">
                Product design
              </h3>

              <p className="mt-6 text-xl text-gray-600 ">
                At Volti Studio, we rely on the unbiased insight we gain from research and data
                analysis to inform all our design decisions. At this point, we translate product
                strategy to prototypes with testable user experiences to make sure that our designs
                are intuitive, and enjoyable to use.
              </p>

              <p className="mt-4 text-xl text-gray-600 ">
                To turn our designs into an outstanding user experience, we add animation and motion
                design, adding those small details that make users smile.
              </p>

              <List
                items={[
                  'Design Exploration',
                  'User Interface Design',
                  'User Experience Design',
                  'Motion Design & Animation',
                  'Design System',
                ]}
              />
            </div>
            <StaticImage
              className="hidden shrink-0 lg:block"
              src="../images/photos/office-design.jpg"
              alt="A designer is working"
              placeholder="blurred"
              layout="fullWidth"
              quality="90"
            />
          </div>

          <div className="xl:items-center lg:grid-cols-2 lg:grid lg:gap-8 ">
            <StaticImage
              className="hidden shrink-0 lg:block"
              src="../images/photos/office-vertical-4.3.jpg"
              alt="The team is working"
              placeholder="blurred"
              layout="fullWidth"
              quality="90"
            />
            <div className="lg:pl-16">
              <p className="text-sm font-bold tracking-wide text-indigo-600 uppercase md:text-base">
                Flawless & Exact
              </p>
              <h3 className="mt-2 text-4xl font-bold text-gray-900 lg:text-6xl" id="development">
                Development
              </h3>

              <p className="mt-6 text-xl text-gray-600 ">
                We’ll translate designs into accessible and responsive frontend interfaces. Our
                extensive experience in modern web technologies, allows us to always pick the best
                tech for the job.
              </p>

              <p className="mt-4 text-xl text-gray-600 ">
                We are exclusively focusing on the JS stack, including React.js, Next.js, Gatsby.js,
                so we can provide expert-level services to our clients.
              </p>

              <List
                items={[
                  'Websites / Landing pages (Gatsby.js, Next.js)',
                  'Web Apps / PWA (React.js, Ember.js)',
                  'Mobile Apps (React Native)',
                  'Backend (Node.js, Headless CMS, Firebase)',
                  'QA Testing',
                ]}
              />
            </div>
          </div>

          <div className="xl:items-center lg:grid-cols-2 lg:grid lg:gap-8 ">
            <div className="lg:pr-16">
              <p className="text-sm font-bold tracking-wide text-indigo-600 uppercase md:text-base">
                Continuous Improvement
              </p>
              <h3 className="mt-2 text-4xl font-bold text-gray-900 lg:text-6xl" id="improvement">
                Maintenance
              </h3>

              <p className="mt-6 text-xl text-gray-600 ">
                When the product is built, we support you with ongoing design and development
                improvements. That includes the development of additional features, bug fixes, and
                analysis of user behavior to optimize your product.
              </p>

              <p className="mt-4 text-xl text-gray-600 ">
                We will help you prevent stagnation and regression and ensure that you continue
                moving towards your product vision.
              </p>

              <List
                items={[
                  'A/B Tests',
                  'Users Behavior Analysis',
                  'Product Optimization',
                  'New Features Development',
                  'Bug Fixes & Regression Tests',
                ]}
              />
            </div>
            <StaticImage
              className="hidden shrink-0 lg:block"
              src="../images/photos/office-board.jpg"
              alt="The team is running a design sprint"
              placeholder="blurred"
              layout="fullWidth"
              quality="90"
            />
          </div>
        </div>
      </Container>

      <Advantages />
    </Layout>
  );
}
